import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  courierPackResponse,
  courierServiceAvailableData,
  courierServiceData,
  listinoData,
  shipData, shipDocumentData,
  shipSpecialServiceData
} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-courier-dhl',
  templateUrl: './courier-dhl.component.html',
  styleUrls: ['./courier-dhl.component.scss']
})
export class CourierDhlComponent implements OnInit {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<courierServiceAvailableData>();

  public bLoadingData: boolean = false;
  public oDhlRateResponse: any = {Notification: null, Service: []};
  public oDhlRateNotification: any = null;
  public oDhlShipNotification: any = null;
  public oDhlShipResponse: any = null;
  public aList: Array<listinoData> = [];
  public aCourierService: Array<courierServiceData> = [];
  public aAvailableService: Array<courierServiceAvailableData> = [];
  public oSpecialService: shipSpecialServiceData = this.vrshippingService.getNewshipSpecialServiceData();
  public bServiceSelected: boolean = false;
  public bPaperless: boolean = false;
  public bShipComplete: boolean = false;
  public oPaperlessDocument: shipDocumentData = this.vrshippingService.getNewShipDocumentData();

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getCourierServiceForRate();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getCourierServiceForRate() {

    this.bLoadingData = true;
    let worldwide: string = this.oShipData.dest_state == 'IT' ? 'IT' : 'WW';

    this.vrshippingService.getCourierServiceRateListDB('DHL', this.oShipData.total_weight, worldwide, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.services && get_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.services.error);
        } else {
          this.aCourierService = get_response.services;
          this.dhlRateRequest();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  dhlRateRequest() {

    this.bLoadingData = true;

    this.vrshippingService.postDHLRateRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.dhl && get_response.dhl.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi disponibili: ' + JSON.stringify(get_response.dhl.error));
        } else {

          if(get_response.dhl.data){
            this.oDhlRateResponse = get_response.dhl.data.Provider ? get_response.dhl.data.Provider : null;
            this.oDhlRateNotification = this.oDhlRateResponse.Notification ? this.oDhlRateResponse.Notification : null;

            let serviceArray: Array<any> = [];

            if(this.oDhlRateResponse && this.oDhlRateResponse.Service && Array.isArray(this.oDhlRateResponse.Service)) serviceArray = this.oDhlRateResponse.Service;
            if(this.oDhlRateResponse && this.oDhlRateResponse.Service && !Array.isArray(this.oDhlRateResponse.Service)) serviceArray.push(this.oDhlRateResponse.Service);

            if(serviceArray) {
              serviceArray.map((service: any) => {
                //service.visible = false;
                let vrsService: Array<any> = [];
                let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('DHL');
                vrsService = this.aCourierService.filter(x => x.courier_service.toString() === service.type);
                if(vrsService.length > 0) {

                  // Add service to available list
                  oAvailableService.list_code = vrsService[0].listino_code;
                  oAvailableService.list_description = vrsService[0].listino_description;
                  oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].price_zone;
                  oAvailableService.courier_service = service.type;
                  oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;
                  if(service.TotalNet && service.TotalNet.Amount) oAvailableService.courier_price = Number(service.TotalNet.Amount);
                  if(!service.TotalNet || !service.TotalNet.Amount) oAvailableService.courier_price = 0;
                  oAvailableService.pickup_date = service.CutoffTime;
                  oAvailableService.pickup_delivery = service.DeliveryTime;

                  if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
                    let aZonePrice: Array<any> = [];
                    if(this.oShipData.dest_state !== 'IT') aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == oAvailableService.country_zone);
                    if(this.oShipData.dest_state == 'IT'){
                      let province: any = [];
                      province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
                      if(province.length > 0) {
                        aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].price_zone);
                        oAvailableService.country_zone = province[0].price_zone;
                      }
                    }

                    if(aZonePrice.length > 0) {
                      oAvailableService.list_price = aZonePrice[0].price;
                    }

                  }

                  // Fuel supplement
                  oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

                  // Insurrence cost
                  if(this.oShipData.insurrence){
                    oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, this.oShipData.dest_state == 'IT' ? vrsService[0].insurrence_it : vrsService[0].insurrence_world);
                    this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
                    this.oSpecialService.type = "II";
                    this.oSpecialService.value = this.oShipData.insurrence_declared;
                    this.oSpecialService.cost = oAvailableService.insurrence_cost;
                    this.oSpecialService.currency = "EUR";
                    oAvailableService.special_services.push(this.oSpecialService);
                  }

                  // DDP
                  if(this.oShipData.duty_type == 'DDP'){
                    this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
                    this.oSpecialService.type = "DD";
                    this.oSpecialService.value = this.oShipData.duty_declared;
                    this.oSpecialService.cost = vrsService[0].ddp;
                    this.oSpecialService.currency = "EUR";
                    oAvailableService.special_services.push(this.oSpecialService);
                    oAvailableService.ddp_cost = vrsService[0].ddp;
                  }

                  // Remote Area
                  let aRemoteArea: Array<any> = [];
                  if(service && service.Charges) aRemoteArea = service.Charges.Charge.filter((x:any) => x.ChargeCode == 'OO' || x.ChargeCode == 'OC');
                  if(aRemoteArea.length > 0) {
                    aRemoteArea.map((x: any) => {
                      if(x.ChargeAmount !== '') oAvailableService.remote_zone_cost = this.oShipData.dest_state == 'IT' ? vrsService[0].remote_area_it : vrsService[0].remote_area_world;
                    });
                  }

                  // Paperless
                  if(service.Charges && service.Charges.Charge.filter((x:any) => x.ChargeCode == 'WY').length > 0) oAvailableService.paperless = true;

                  this.aAvailableService.push(oAvailableService);

                }
              });
              this.aAvailableService.sort((a, b) => a.list_price - b.list_price);
            }
          }
        }

        setTimeout(()=>{
          this.bLoadingData = false;
        },1000);


      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  selectService(service: courierServiceAvailableData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler spedire con questo servizio: " + service.courier_code +  " - " + service.list_description + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.saveAnswer.emit(service);
        this.bServiceSelected = true;
        this.bPaperless = service.paperless;
        this.completeShipData(service);
      }
    });

  }

  completeShipData(service: courierServiceAvailableData){

    this.oShipData.courier_code = service.courier_code;
    this.oShipData.courier_price = service.courier_price;
    this.oShipData.courier_service = service.courier_service;
    this.oShipData.delivery = service.pickup_delivery;
    this.oShipData.duty_cost = service.ddp_cost;
    this.oShipData.fuel_price = service.fuel_cost;
    this.oShipData.insurrence_cost = service.insurrence_cost;
    this.oShipData.pickupdate = service.pickup_date;
    this.oShipData.price = service.list_price;
    this.oShipData.remote_zone = service.remote_zone_cost;
    this.oShipData.special_services = service.special_services;

    if(!this.bPaperless) this.insertShipRequest();

  }

  insertShipRequest() {

    this.bPaperless = false;
    this.bLoadingData = true;

    this.vrshippingService.postShipDataInsertDB(this.oShipData, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.ship && insert_response.ship.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare la spedizione: ' + JSON.stringify(insert_response.ship.error));
          this.bLoadingData = false;
        } else {
          this.oShipData.id = insert_response.ship.id;
          this.sendShipRequest();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  sendShipRequest() {

    this.bLoadingData = true;

    this.vrshippingService.postDHLShipRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.dhl && get_response.dhl.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile contattare i server DHL: ' + JSON.stringify(get_response.dhl.error));
        } else {

          this.oDhlShipResponse = get_response.dhl.data.ShipmentIdentificationNumber ? get_response.dhl.data : null;
          this.oDhlShipNotification = get_response.dhl.data.Notification ? get_response.dhl.data.Notification : null;

          if(!get_response.dhl.data.ShipmentIdentificationNumber) this.deleteShipRequest();

          if(this.oDhlShipResponse) {
            // update ship data
            this.oShipData.ship_id = this.oDhlShipResponse.ShipmentIdentificationNumber;
            this.oShipData.dispatch_number = this.oDhlShipResponse.DispatchConfirmationNumber;
            this.oShipData.pdf_base64 = this.oDhlShipResponse.LabelImage.GraphicImage;

            let oPackResponse: courierPackResponse = {
              tracking_number: "",
              number: 1
            }

            if(Array.isArray(this.oDhlShipResponse.PackagesResult.PackageResult)){
              this.oDhlShipResponse.PackagesResult.PackageResult.map((packdata: any) => {

                oPackResponse = {
                  tracking_number: "",
                  number: 1
                }
                oPackResponse.tracking_number = packdata.TrackingNumber;
                oPackResponse.number = packdata.number;

                this.oShipData.packages_courier_response.push(oPackResponse);
              });

            } else {
              oPackResponse = {
                tracking_number: this.oDhlShipResponse.PackagesResult.PackageResult.TrackingNumber,
                number: this.oDhlShipResponse.PackagesResult.PackageResult.number
              }
              this.oShipData.packages_courier_response.push(oPackResponse);
            }
            this.updateShipRequest();
          }
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updateShipRequest() {

    this.vrshippingService.postShipDataUpdateDB(this.oShipData, this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.ship && update_response.ship.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare la spedizione: ' + JSON.stringify(update_response.ship.error));
        } else {
          this.downloadPdf(this.oShipData.pdf_base64, "dhl_shipment_label_"+ this.oShipData.ship_id + ".pdf");
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deleteShipRequest() {

    this.vrshippingService.postShipDataDeleteDB(this.oShipData, this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.ship && update_response.ship.error) {
          //Error
          // Do nothing
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  newShipping() {
    this.bServiceSelected = false;
    this.bPaperless = false;
    this.oDhlShipResponse = null;
    this.oDhlShipNotification = null;
    this.oDhlRateResponse = null;
    this.oDhlRateNotification = null;
    this.aAvailableService = [];

    let newService: courierServiceAvailableData = this.vrshippingService.getNewCourierServiceAvailableData();
    this.saveAnswer.emit(newService);
  }

  downloadPdf(base64String: string, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(){
    this.downloadPdf(this.oShipData.pdf_base64,"dhl_shipment_label_"+ this.oShipData.ship_id + ".pdf");
  }

  /************************************/
  /***** PAPERLESS DOCUMENTS **********/
  /************************************/

  addPaperlessFile() {
    if(this.oPaperlessDocument.type !== '--' && this.oPaperlessDocument.b64_image !== '') this.oShipData.documents.push(this.oPaperlessDocument);
    this.oPaperlessDocument = this.vrshippingService.getNewShipDocumentData();
  }

  removePaperlessFile(doc: shipDocumentData) {
    this.oShipData.documents = this.oShipData.documents.filter(x => x.file_name !== doc.file_name);
  }

  public picked(event: any) {

    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.oPaperlessDocument.format = fileList[0].name.split(".")[1];
      this.oPaperlessDocument.file_name = fileList[0].name;
      this.handleInputChange(fileList[0]);
    }

  }

  handleInputChange(files: any) {
    const file = files;
    const reader = new FileReader();
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    const base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    this.oPaperlessDocument.b64_image = base64result;
  }

}
