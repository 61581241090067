import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  courierPackResponse,
  courierServiceAvailableData,
  courierServiceData,
  shipData,
  shipSpecialServiceData
} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-courier-brt',
  templateUrl: './courier-brt.component.html',
  styleUrls: ['./courier-brt.component.scss']
})
export class CourierBrtComponent implements OnInit {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<courierServiceAvailableData>();

  public bLoadingData: boolean = false;
  public bServiceSelected: boolean = false;
  public aCourierService: Array<courierServiceData> = [];
  public oSpecialService: shipSpecialServiceData = this.vrshippingService.getNewshipSpecialServiceData();
  public aAvailableService: Array<courierServiceAvailableData> = [];
  public oBRTShipNotification: any = null;
  public oBRTShipResponse: any = null;

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    if(this.oShipData.dest_state == 'IT') this.getCourierServiceForRate();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getCourierServiceForRate() {

    this.bLoadingData = true;
    let worldwide: string = this.oShipData.dest_state == 'IT' ? 'IT' : 'WW';

    this.vrshippingService.getCourierServiceRateListDB('BRT', this.oShipData.total_weight, worldwide, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.services && get_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.services.error);
        } else {
          this.aCourierService = get_response.services;
          if(!this.vrshippingService.bPallet) this.brtRateRequest();
          if(this.vrshippingService.bPallet) this.definePalletService();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  brtRateRequest() {
    this.bLoadingData = true;
    this.defineDefaultService();
    this.definePriorityService();
    this.bLoadingData = false;
  }

  defineDefaultService() {
    // Define default service
    let vrsService: Array<any> = [];
    let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('BRT');
    vrsService = this.aCourierService.filter(x => x.courier_service.toString() === 'Default');
    if(vrsService.length > 0) {
      // Add service to available list
      oAvailableService.list_code = vrsService[0].listino_code;
      oAvailableService.list_description = vrsService[0].listino_description;
      oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].price_zone;
      oAvailableService.courier_service = "Default";
      oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;
      oAvailableService.courier_price = 0;
      oAvailableService.pickup_date = this.oShipData.date + "T" + this.oShipData.hour;
      oAvailableService.pickup_delivery = "";

      if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
        let aZonePrice: Array<any> = [];
        let province: any = [];
        province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
        if(province.length > 0) {
          aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].price_zone);
          oAvailableService.country_zone = province[0].price_zone;
        }

        if(aZonePrice.length > 0) {
          oAvailableService.list_price = aZonePrice[0].price;
        }
      }

      // Fuel supplement
      oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

      // Insurrence cost
      if(this.oShipData.insurrence){
        oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, vrsService[0].insurrence_it);
        this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
        this.oSpecialService.type = "II";
        this.oSpecialService.value = this.oShipData.insurrence_declared;
        this.oSpecialService.cost = oAvailableService.insurrence_cost;
        this.oSpecialService.currency = "EUR";
        oAvailableService.special_services.push(this.oSpecialService);
      }

      // DDP
      // Not applicable

      // Remote Area
      // TODO

      this.aAvailableService.push(oAvailableService);

    }
  }

  definePriorityService() {
    // Define default service
    let vrsService: Array<any> = [];
    let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('BRT');
    vrsService = this.aCourierService.filter(x => x.courier_service.toString() === 'E');
    if(vrsService.length > 0) {
      // Add service to available list
      oAvailableService.list_code = vrsService[0].listino_code;
      oAvailableService.list_description = vrsService[0].listino_description;
      oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].price_zone;
      oAvailableService.courier_service = "E";
      oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;
      oAvailableService.courier_price = 0;
      oAvailableService.pickup_date = this.oShipData.date + " " + this.oShipData.hour;
      oAvailableService.pickup_delivery = "";

      if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
        let aZonePrice: Array<any> = [];
        let province: any = [];
        province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
        if(province.length > 0) {
          aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].price_zone);
          oAvailableService.country_zone = province[0].price_zone;
        }

        if(aZonePrice.length > 0) {
          oAvailableService.list_price = aZonePrice[0].price;
        }
      }

      // Fuel supplement
      oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

      // Insurrence cost
      if(this.oShipData.insurrence){
        oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, vrsService[0].insurrence_it);
        this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
        this.oSpecialService.type = "II";
        this.oSpecialService.value = this.oShipData.insurrence_declared;
        this.oSpecialService.cost = oAvailableService.insurrence_cost;
        this.oSpecialService.currency = "EUR";
        oAvailableService.special_services.push(this.oSpecialService);
      }

      // DDP
      // Not applicable

      // Remote Area
      // TODO

      this.aAvailableService.push(oAvailableService);

    }
  }

  defineOre10Service() {
    // Define default service
    let vrsService: Array<any> = [];
    let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('BRT');
    vrsService = this.aCourierService.filter(x => x.courier_service.toString() === 'H');
    if(vrsService.length > 0) {
      // Add service to available list
      oAvailableService.list_code = vrsService[0].listino_code;
      oAvailableService.list_description = vrsService[0].listino_description;
      oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].price_zone;
      oAvailableService.courier_service = "H";
      oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;
      oAvailableService.courier_price = 0;
      oAvailableService.pickup_date = this.oShipData.date + " " + this.oShipData.hour;
      oAvailableService.pickup_delivery = "";

      if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
        let aZonePrice: Array<any> = [];
        let province: any = [];
        province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
        if(province.length > 0) {
          aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].price_zone);
          oAvailableService.country_zone = province[0].price_zone;
        }

        if(aZonePrice.length > 0) {
          oAvailableService.list_price = aZonePrice[0].price;
        }
      }

      // Fuel supplement
      oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

      // Insurrence cost
      if(this.oShipData.insurrence){
        oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, vrsService[0].insurrence_it);
        this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
        this.oSpecialService.type = "II";
        this.oSpecialService.value = this.oShipData.insurrence_declared;
        this.oSpecialService.cost = oAvailableService.insurrence_cost;
        this.oSpecialService.currency = "EUR";
        oAvailableService.special_services.push(this.oSpecialService);
      }

      // DDP
      // Not applicable

      // Remote Area
      // TODO

      this.aAvailableService.push(oAvailableService);

    }
  }

  definePalletService() {
    this.bLoadingData = true;
    // Define pallet service
    let vrsService: Array<any> = [];
    let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('BRT');
    vrsService = this.aCourierService.filter(x => x.listino_code === 'PALLET');
    if(vrsService.length > 0) {
      // Add service to available list
      oAvailableService.list_code = vrsService[0].listino_code;
      oAvailableService.list_description = vrsService[0].listino_description;
      oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].pallet_zone;
      oAvailableService.courier_service = "Default";
      oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;
      oAvailableService.courier_price = 0;
      oAvailableService.pickup_date = this.oShipData.date + "T" + this.oShipData.hour;
      oAvailableService.pickup_delivery = "";

      if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
        let aZonePrice: Array<any> = [];
        let province: any = [];
        province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
        if(province.length > 0) {
          aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].pallet_zone);
          oAvailableService.country_zone = province[0].pallet_zone;
        }

        if(aZonePrice.length > 0) {
          oAvailableService.list_price = aZonePrice[0].price;
        }
      }

      // Fuel supplement
      oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

      // Insurance cost
      if(this.oShipData.insurrence){
        oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, vrsService[0].insurrence_it);
        this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
        this.oSpecialService.type = "II";
        this.oSpecialService.value = this.oShipData.insurrence_declared;
        this.oSpecialService.cost = oAvailableService.insurrence_cost;
        this.oSpecialService.currency = "EUR";
        oAvailableService.special_services.push(this.oSpecialService);
      }

      // DDP
      // Not applicable

      // Remote Area
      // TODO

      this.aAvailableService.push(oAvailableService);

      this.bLoadingData = false;

    }
  }

  selectService(service: courierServiceAvailableData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler spedire con questo servizio: " + service.courier_code +  " - " + service.list_description + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: any) => {
      if(confirmation) {

        /*this.oShipData.pallet_EPAL = confirmation.pallet_EPAL;
        this.oShipData.pallet_EUR = confirmation.pallet_EUR;
        this.oShipData.pallet_tail_lift = confirmation.pallet_tail_lift;*/

        this.saveAnswer.emit(service);
        this.bServiceSelected = true;
        this.completeShipData(service);
      }
    });

  }

  completeShipData(service: courierServiceAvailableData){

    this.oShipData.courier_code = service.courier_code;
    this.oShipData.courier_price = service.courier_price;
    this.oShipData.courier_service = service.courier_service;
    this.oShipData.delivery = service.pickup_delivery;
    this.oShipData.duty_cost = service.ddp_cost;
    this.oShipData.fuel_price = service.fuel_cost;
    this.oShipData.insurrence_cost = service.insurrence_cost;
    this.oShipData.pickupdate = service.pickup_date;
    this.oShipData.price = service.list_price;
    this.oShipData.remote_zone = service.remote_zone_cost;

    this.insertShipRequest();

  }

  insertShipRequest() {

    this.bLoadingData = true;

    /*this.vrshippingService.postShipDataInsertDB(this.oShipData, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.ship && insert_response.ship.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare la spedizione: ' + JSON.stringify(insert_response.ship.error));
          this.bLoadingData = false;
        } else {
          this.oShipData.id = insert_response.ship.id;
          this.sendShipRequest();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );*/
    this.sendShipRequest();

  }

  sendShipRequest() {

    this.bLoadingData = true;

    this.vrshippingService.postBRTShipRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.brt && get_response.brt.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile contattare i server BRT: ' + JSON.stringify(get_response.brt.error));
        } else {

          this.oBRTShipResponse = get_response.brt.success;

          this.oBRTShipNotification = null;
          if(this.oBRTShipResponse.createResponse.executionMessage && this.oBRTShipResponse.createResponse.executionMessage.code < 0) {
            this.oBRTShipNotification = get_response.brt.data.createResponse.executionMessage;
          }

          if(this.oBRTShipResponse.createResponse.executionMessage && this.oBRTShipResponse.createResponse.executionMessage.code >= 0) {
            // Success
            this.oBRTShipResponse.createResponse.labels.label.forEach((x: any)=>{
              this.downloadPdf(x.stream, 'brt_' + x.parcelID + '.pdf');
            });
          }
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  newShipping() {
    this.bServiceSelected = false;
    //this.oDhlShipResponse = null;
    this.oBRTShipNotification = null;
    //this.oDhlRateResponse = null;
    //this.oDhlRateNotification = null;
    this.aAvailableService = [];

    let newService: courierServiceAvailableData = this.vrshippingService.getNewCourierServiceAvailableData();
    this.saveAnswer.emit(newService);
  }

  downloadPdf(base64String: string, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

}
