import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  @Input() type: string = "";
  @Input() message: string = "";
  @Output() questionAnswer = new EventEmitter<boolean>();
  //@Output() questionAnswerBRT = new EventEmitter<any>();
  @Output() questionAnswerUPS = new EventEmitter<any>();

  /*public brtOtherData: any = {
    pallet_EPAL: 0,
    pallet_EUR: 0,
    pallet_tail_lift: " "
  };*/
  public upsPickUpData: any = {
    closeTime: "1700", // Forma HHmm
    residentialPlace: "N", // Y - residential / N - commercial
    containerCode: "01", // 01 - Package / 02 - UPS Letter
    pickupNotes: "" // Note
  }

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  getConfirmResponse(value: boolean) {
    this.questionAnswer.emit(value);
    this.activeModal.close();
  }

  getConfirmResponseUPS() {
    this.questionAnswerUPS.emit(this.upsPickUpData);
    this.activeModal.close();
  }

  /*getConfirmResponseBRT() {
    this.questionAnswerBRT.emit(this.brtOtherData);
    this.activeModal.close();
  }*/

}
