<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row" *ngIf="!bServiceSelected">
  <div class="col-12 mt-2 mb-2" *ngIf="bLoadingData">
    <span><i class="fa fa-spinner fa-spin"></i> Caricamento servizi UPS in corso...</span>
  </div>
  <div class="col-12 mt-2">
    <div class="d-flex flex-wrap justify-content-center">
      <div class="mt-3 me-3 p-2 border bg-light col-5" *ngFor="let service of aAvailableService">
        <div class="row">
          <div class="col-3">
            <img src="assets/img/ups/logo.png" alt="" class="img-fluid" />
          </div>
          <div class="col-9 text-center">
            <div class="border border-success text-success rounded p-1 fw-bolder" *ngIf="service.list_price > 0">
              {{ service.list_price + service.fuel_cost + service.insurrence_cost + service.remote_zone_cost + service.ddp_cost | number:'1.2-2' }} €
              <div class="small text-muted">
                {{service.courier_service_desc}}
              </div>
            </div>
            <div class="bg-light-orange border rounded p-1 fw-bolder" *ngIf="service.list_price == 0">
              n.a.
              <div class="small text-muted">
                {{service.courier_service_desc}}
              </div>
            </div>
          </div>
          <div class="col-6 mt-3">
            <table class="table">
              <thead>
              <tr>
                <th class="small text-muted">Voce</th>
                <th class="small text-muted text-end">Prezzo</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Spedizione</td>
                <td class="text-end">
                  <span *ngIf="service.list_price > 0">{{service.list_price | number: '1.2-2'}}</span>
                  <span *ngIf="service.list_price == 0">n.a.</span>
                </td>
              </tr>
              <tr *ngIf="service.fuel_cost > 0">
                <td>Suppl. Fuel</td>
                <td class="text-end">{{service.fuel_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.insurrence_cost > 0">
                <td>Assicurazione</td>
                <td class="text-end">{{service.insurrence_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.remote_zone_cost > 0">
                <td>Zona remota</td>
                <td class="text-end">{{service.remote_zone_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.ddp_cost > 0">
                <td>Disbrigo pratiche DDP</td>
                <td class="text-end">{{service.ddp_cost | number: '1.2-2'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 mt-3 text-center">
            <div class="mt-2 small">
              <span class="text-muted">Ritiro</span>
              <div>{{service.pickup_date | date: 'dd/MM/yyyy HH:mm'}}</div>
            </div>
            <div class="mt-2 small">
              <span class="text-muted">Consegna</span>
              <div>{{service.pickup_delivery | date: 'dd/MM/yyyy'}} entro le {{service.pickup_delivery | date: 'HH:mm'}}</div>
            </div>
            <div class="mt-3">
              <button class="btn btn-sm btn-primary" *ngIf="service.list_price > 0 && oShipData.drop_off_type == 'REGULAR_PICKUP'" (click)="selectService(service)">SELEZIONA SERVIZIO</button>
              <button class="btn btn-sm btn-primary" *ngIf="service.list_price > 0 && oShipData.drop_off_type == 'REQUEST_COURIER'" (click)="selectServicePickup(service)">SELEZIONA SERVIZIO</button>

              <div class="text-danger" *ngIf="service.list_price == 0">
                Chiama per un preventivo
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="bPaperless">
  <div class="col-12 mt-2 mb-2">
    <h4>Servizio Paperless UPS (Opzionale)</h4>
    <p>
      UPS Paperless è un servizio gratuito che consente al mittente di fornire i documenti necessari all’esportazione (come le fatture commerciali o proforma) in formato digitale, caricandoli durante la creazione delle spedizioni.
      Questo servizio elimina la necessità di stampare e allegare manualmente i documenti, facendoti risparmiare tempo e denaro e salvaguardando l'ambiente.
    </p>
  </div>
  <div class="col-12 col-md-4 mt-2">
    <label>Tipo documento</label>
    <select class="form-control mt-2" [(ngModel)]="oPaperlessDocument.type">
      <option value="--">-- Seleziona il tipo di documento --</option>
      <option value="001">001 - Authorization Form</option>
      <option value="002">002 - Commercial Invoice</option>
      <option value="003">003 - Certificate of Origin</option>
      <option value="004">004 - Export Accompanying Document</option>
      <option value="005">005 - Export License</option>
      <option value="006">006 - Import Permit</option>
      <option value="007">007 - One Time NAFTA</option>
      <option value="008">008 - Other Document</option>
      <option value="009">009 - Power of Attorney</option>
      <option value="010">010 - Packing List</option>
      <option value="011">011 - SED Document</option>
      <option value="012">012 - Shipper's Letter of Instruction</option>
      <option value="013">013 - Declaration</option>
    </select>
  </div>
  <div class="col-12 col-md-4 mt-2">
    <label>Seleziona il documento <i class="fa fa-info-circle" [ngbTooltip]="tipFormat"></i></label>
    <ng-template #tipFormat>
      BMP, DOC, GIF, JPG, PDF, PNG, RTF, TIF, TXT, XLS, DOCX E XLSX
    </ng-template>
    <input type="file" class="form-control mt-2" accept=".bmp, .doc, .gif, .jpg, .pdf, .png, .rtf, .tif, .txt, .xls, .docx, .xlsx" (change)="picked($event)">
  </div>
  <div class="col-12 col-md-4 mt-2 text-center">
    <button class="btn btn-primary mt-3" (click)="addPaperlessFile()" [disabled]="oShipData.documents.length >= 13">AGGIUNGI FILE</button>
  </div>

  <div class="col-12 mt-3">
    <table class="table table-responsive table-hover table-striped table-bordered">
      <thead>
      <tr >
        <th>Tipo</th>
        <th>Nome file</th>
        <th>Estensione</th>
        <th>#</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let doc of oShipData.documents">
        <td>{{doc.type}}</td>
        <td>{{doc.file_name}}</td>
        <td>{{doc.format}}</td>
        <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removePaperlessFile(doc)"><i class="fa fa-trash"></i></button></td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="col-12 mt-4 mb-2 text-center">
    <button class="btn btn-success" (click)="uploadFilesToUps()">CONFERMA SPEDIZIONE</button>
  </div>

</div>

<div class="row" *ngIf="bServiceSelected && !bPaperless && !bLoadingData">

  <div class="col-12">
    <div class="alert alert-danger" *ngFor="let error of oUPSShipNotification">
      <div class="mt-2">
        <h5>Errore nella chiamata UPS</h5>
        {{ error.code }} - {{error.message}}
      </div>
    </div>

    <div class="mb-2" *ngFor="let label of oUPSShipResponse">

      <div class="alert alert-success" >

        <h4>SPEDIZIONE CREATA CON SUCCESSO</h4>

        <div class="mt-3">
          <div class="mt-2">
            <div><strong>Master ID</strong> {{label.ship_id}}</div>
          </div>
          <div class="mt-2">
            <div><strong>Traking</strong> {{label.tracking}}</div>
          </div>
          <div class="mt-2">
            <div><strong>Pickup PRN</strong> {{oUPSPickupData.PRN}}</div>
          </div>


          <div class="text-center mt-3">
            <button class="btn btn-success" (click)="downloadPdf(label.stream, 'ups_' + label.tracking)">SCARICA ETICHETTA GIF</button>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="oUPSPickupErrors.length > 0">

      <h4>PICKUP NON CREATO</h4>
      Il pickup richiesto non è stato creato a causa di alcuni errori che troverai di seguito.<br />
      Per confermare il pickup è necessario contattare l'assistenza all'indirizzo email <strong>assistenza&#64;veronasped.it</strong>

      <div class="mt-2" *ngFor="let error of oUPSPickupErrors">
        <h5>Error code {{ error.code }}</h5>
         {{error.message}}
      </div>
    </div>

  </div>

  <div class="col-12 mt-3 text-center">
    <button class="btn btn-sm btn-primary" (click)="newShipping()">NUOVA SPEDIZIONE</button>
  </div>

</div>


