import { Injectable } from '@angular/core';
import {
  addressProfile,
  courierData,
  courierServiceData,
  courierUserData,
  listinoShipData,
  listinoShipDetailsData,
  listinoShipDuplicateData,
  listinoShipUpdateData,
  listinoShipUpgradeData, shipData, shipDocumentData, shipSpecialServiceData,
  userData,
  userProfileData
} from '../interfaces/vrs_interfaces';
import {Observable} from "rxjs";
import {ApiService} from "./api.service";
import {TranslateService} from "@ngx-translate/core";
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VrshippingService {

  public settingsData: any = null;
  public userData: userData = this.getNewUserData();
  public bLoggedIn: boolean = false;
  public bPallet: boolean = false;

  constructor(private apiService: ApiService, private translateService: TranslateService,
              private http: HttpClient,) { }

  setSettings(oSettings: any) {
    this.settingsData = oSettings;
    this.getUserLoginData ();
  }

  getNewUserData () {

    let objUser = {
      id: 0,
      azienda: '',
      nome: '',
      cognome: '',
      email: '',
      stato: '',
      type: '',
      token: '',
      username: '',
    }

    return objUser;

  }

  getNewUserProfileData() {

    let obj: any = {
      id: 0,
      reference: '',
      nome: '',
      cognome: '',
      email: '',
      psw: '',
      azienda: '',
      indirizzo: '',
      citta: '',
      prov: '--',
      cap: '',
      nazione: '--',
      tel: '',
      stato: 'A',
      top_client: '',
      type: 'C',
      username: '',
      usstate: '--',
    }

    return obj;

  }

  getUserLoginData () {

    const sUserData = localStorage.getItem('gest_userinfo');
    if (sUserData) {
      this.userData = JSON.parse(sUserData);
      this.bLoggedIn = true;
    }

  }

  setUserLoginData (oUserData: any) {
    this.userData = oUserData;
    localStorage.setItem('gest_userinfo', JSON.stringify(this.userData));
  }

  getUserLogoutData () {
    this.userData = this.getNewUserData();
    localStorage.removeItem('gest_userinfo');
  }

  getNewAddressProfile() {

    let obj: any = {
      id: 0,
      id_user: 0,
      reference: '',
      rif_nome: '',
      rif_cognome: '',
      rif_email: '',
      azienda: '',
      indirizzo: '',
      indirizzo2: '',
      citta: '',
      prov: '--',
      cap: '',
      nazione: '--',
      usstate: '',
      tel: '',
      stato: ''
    }

    return obj;

  }

  getNewCourierData() {

    let obj: any = {
      id: 0,
      code: '',
      description: '',
      remote_area_it: 1,
      remote_area_world: 10,
      insurrence_perc: 2,
      insurrence_it: 12,
      insurrence_world: 15,
      ddp: this.settingsData.ddpTaxValue,
      status: true,
    }

    return obj;

  }

  getNewUserCourierData(user_id: number, courier_code: string) {
    let obj: any = {

      id: 0,
      id_user: user_id,
      courier_code: courier_code,
      status: true

    }

    return obj;

  }

  getNewCourierServiceData() {

    let obj: any = {
      id: 0,
      listino_code: "--",
      courier_code: "",
      courier_service: "",
      courier_service_desc: "",
      fuel: 0,
      worldwide: "IT",
    }

    return obj;

  }

  getNewCourierServiceAvailableData() {

    let obj: any = {
      courier_code: "",
      list_code: "",
      list_description: "",
      country_zone: 0,
      courier_service: "",
      courier_service_desc: "",
      courier_price: 0,
      list_price: 0,
      pickup_date: "",
      pickup_delivery: "",
      insurrence_cost: 0,
      ddp_cost: 0,
      remote_zone_cost: 0,
      paperless: 0,
      fuel_cost: 0,
      special_services: []
    }

    return obj;

  }

  getNewListinoShipData(){
    let obj: any = {
      data: [],
      list_update: []
    }
    return obj;
  }

  getNewListinoShipDetailsData() {

    let obj: any = {
      id: 0,
      peso_min: 0,
      peso_max: 0,
      zone: 0,
      price: 0,
      cod_vrsped: '',
      courier_code: '',
      type: '',
      date_upd: ''
    }

    return obj;

  }

  getNewListinoShipUpdateData() {
    let obj: any = {
      id: 0,
      list_code: "",
      operator: "",
      perc: 0,
      date_ins: "",
    }
    return obj;
  }

  getNewListShipDuplicatorData() {

    let obj: any = {
      origin_courier: "",
      destination_courier: ""
    }

    return obj;

  }

  getNewListShipUpgradeData() {

    let obj: any = {
      courier_code: "",
      list_code: "",
      perc_value: 0,
      operator: "plus",
    }

    return obj;

  }

  getNewshipData() {

    let obj: any = {
      id: 0,
      courier_code: "",
      client_token: "",
      client_reference: "",
      ship_id: "",
      dispatch_number: "",
      pdf_base64: "",
      //economic_value: 0,
      cod_mit: 0,
      drop_off_type: "REQUEST_COURIER",
      mit_agency: "",
      mit_reference: "",
      mit_phone: "",
      mit_email: "spedizioni@veronasped.it",
      mit_state: "",
      mit_city: "",
      mit_address: "",
      mit_address_2: "",
      mit_zip: "",
      mit_us_prov: "",
      cod_dest: 0,
      dest_agency: "",
      dest_reference: "",
      dest_phone: "",
      dest_email: "",
      dest_state: "--",
      dest_city: "",
      dest_address: "",
      dest_address_2: "",
      dest_zip: "",
      dest_us_prov: "--",
      type: "DOCUMENTS",
      date: null,
      hour: "09:00:00",
      general_desc: "",
      courier_service: "",
      price: 0,
      fuel_price: 0,
      insurrence_declared: 0,
      insurrence_cost: 0,
      duty_type: "DAP",
      duty_declared: 0,
      duty_cost: 0,
      remote_zone: 0,
      courier_price: 0,
      pickupdate: "",
      delivery: "",
      pickupLocation: "",
      special_services: [],
      packages: [],
      packages_courier_response: [],
      documents: [],
      total_weight: 0,
      pallet_EPAL: 0,
      pallet_EUR: 0,
      pallet_tail_lift: " "
    }

    return obj;

  }

  getNewShipPackageData() {

    let obj: any = {
      id: 0,
      ship_id: "",
      ship_data_id: 0,
      number: 0,
      weight: 0,
      weight_vol: 0,
      weight_declared: 0,
      length: 0,
      width: 0,
      height: 0,
      ref: "",
      tracking: "",
      stream: ""
    }

    return obj;

  }

  getNewShipDocumentData() {

    let obj: any = {
      id: 0,
      doc_id: '',
      type: "--",
      file_name: "",
      format: "",
      b64_image: ""
    }

    return obj;

  }

  getNewshipSpecialServiceData() {

    let obj: any = {
      id: 0,
      ship_id: "",
      type: "",
      value: 0,
      cost: 0,
      currency: "",
    }

    return obj;

  }

  getNewAvailableCourierService(courier_code: string) {

    let obj: any = {

      courier_code: courier_code,
      list_code: "",
      list_description: "",
      country_zone: 0,
      courier_service: "",
      courier_service_desc: "",
      courier_price: 0,
      list_price: 0,
      pickup_date: "",
      pickup_delivery: "",
      insurrence_cost: 0,
      ddp_cost: 0,
      remote_zone_cost: 0,
      paperless: false,
      fuel: 0,
      special_services: []
    }

    return obj;

  }

  getInsurranceCost (shipValue: number, courier_insurrence_perc: number, courier_insurrence_minVal: number) {

    let cost: number = (shipValue * 2) / 100;
    if(cost < courier_insurrence_minVal) return courier_insurrence_minVal;
    if(cost > courier_insurrence_minVal) return cost;
    return -999;

  }


  /*****************************************/
  /******* API CALLS GET *******************/
  /*****************************************/

  getCourierListDB(token: string, courier_id?: number): Observable<any>{

    let apiUrl: string = "courier_get_list.php";
    if(courier_id) apiUrl = apiUrl + "?id=" + courier_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getUsersListDB(token: string, user_id?: number): Observable<any>{

    let apiUrl: string = "user_get_list.php";
    if(user_id) apiUrl = apiUrl + "?id=" + user_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getAddressProfileListDB(token: string, type:string, profile_id?: number): Observable<any> {
    // type -> sender or receiver
    let apiUrl: string = type + "_get_list.php";
    if(profile_id) apiUrl = apiUrl + "?id=" + profile_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getListinoListDB(token: string, list_id?: number): Observable<any>{

    let apiUrl: string = "listino_get_list.php";
    if(list_id) apiUrl = apiUrl + "?id=" + list_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getListinoShipListDB(courier_code: string, token: string): Observable<any>{

    let apiUrl: string = "listino_ship_get_list.php?c=" + courier_code;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCourierServiceListDB(courier_code: string, token: string, service_id?: number): Observable<any>{

    let apiUrl: string = "service_get_list.php";
    apiUrl = apiUrl + "?c=" + courier_code;
    if(service_id) apiUrl = apiUrl + "&id=" + service_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCourierServiceRateListDB(courier_code: string, ship_weight: number, worldwide: string, token: string): Observable<any>{

    let apiUrl: string = "service_get_list_for_rate.php";
    apiUrl = apiUrl + "?c=" + courier_code +"&w=" + ship_weight + "&ww=" + worldwide;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getUserCourierListDB(id_user: number, token: string): Observable<any>{

    let apiUrl: string = "user_courier_get_list.php";
    apiUrl = apiUrl + "?id=" + id_user;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getShipDataListDB(from_dt: string, to_dt:string, token: string): Observable<any>{

    let apiUrl: string = "ship_get_list.php?from="+ from_dt +"&to=" + to_dt;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  /*****************************************/
  /******* API CALLS POST ******************/
  /*****************************************/

  postLoginUserDB(oUserLoginData: any): Observable<any>{
    return this.apiService.post('login_user.php', '', oUserLoginData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postCourierUpdateDB(oCourierData: courierData, token: string): Observable<any>{
    return this.apiService.post('courier_update.php', token, oCourierData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserInsertDB(oUserData: userProfileData, token: string): Observable<any>{
    return this.apiService.post('user_insert.php', token, oUserData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserUpdateDB(oUserData: userProfileData, token: string): Observable<any>{
    return this.apiService.post('user_update.php', token, oUserData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserDeleteDB(oUserData: userProfileData, token: string): Observable<any>{
    return this.apiService.post('user_delete.php', token, oUserData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postAddressProfileInsertDB(oAddressProfileData: addressProfile, type: string, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post(type + '_insert.php', token, oAddressProfileData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postAddressProfileUpdateDB(oAddressProfileData: addressProfile, type: string, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post(type + '_update.php', token, oAddressProfileData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postCourierServiceInsertDB(oCourierservice: courierServiceData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('service_insert.php', token, oCourierservice).pipe(
      map(data => {
        return data;
      })
    );
  }

  postCourierServiceUpdateDB(oCourierservice: courierServiceData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('service_update.php', token, oCourierservice).pipe(
      map(data => {
        return data;
      })
    );
  }

  postCourierServiceDeleteDB(oCourierservice: courierServiceData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('service_delete.php', token, oCourierservice).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserCourierInsertDB(oUserCourier: courierUserData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('user_courier_insert.php', token, oUserCourier).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserCourierUpdateDB(oUserCourier: courierUserData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('user_courier_update.php', token, oUserCourier).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserCourierDeleteDB(oUserCourier: courierUserData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('user_courier_delete.php', token, oUserCourier).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserCourierAllUserInsertDB(oCourier: courierData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_all_user.php', token, oCourier).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUserCourierAllUserDeleteDB(oCourier: courierData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_all_user_delete.php', token, oCourier).pipe(
      map(data => {
        return data;
      })
    );
  }

  postListinoShipAPIDB(oListinoShip: listinoShipDetailsData, type: string, token: string, bAllRecord?: boolean): Observable<any>{
    // type -> sender or receiver
    let ApiURl = 'listino_ship_'+ type +'.php';
    if(bAllRecord) ApiURl = ApiURl + "?all=true";

    return this.apiService.post(ApiURl, token, oListinoShip).pipe(
      map(data => {
        return data;
      })
    );
  }

  postListinoDuplicateDB(oListinoShip: listinoShipDuplicateData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('listino_ship_duplicate.php', token, oListinoShip).pipe(
      map(data => {
        return data;
      })
    );
  }

  postListinoShipUpgradeDB(oUpgrade: listinoShipUpgradeData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('listino_ship_upgrade_all.php', token, oUpgrade).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDHLRateRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_dhl/dhl_RateRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDHLShipRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_dhl/dhl_ShipRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDHLTrackingRequestDB(oTreckingData: any, token: string): Observable<any>{
    return this.apiService.post('courier_dhl/dhl_TrackingRequest.php', token, oTreckingData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postShipDataInsertDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('ship_insert.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postShipDataUpdateDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('ship_update.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postShipDataDeleteDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('ship_delete.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postFedexRateRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_fedex/fedex_rest_RateRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postFedexShipRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_fedex/fedex_rest_ShipRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postFedexTrackingRequestDB(oShipData: shipData, token: string): Observable<any>{
    return this.apiService.post('courier_fedex/fedex_rest_TrackingRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postBRTShipRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_brt/brt_ShipRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postBRTCreateFileDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_brt/brt_regenerate_file.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postBRTTrackingRequestDB(oShipData: shipData, token: string): Observable<any>{
    return this.apiService.post('courier_brt/brt_TrackingRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUPSRateRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_ups/ups_rest_RateRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUPSShipRequestDB(oShipData: shipData, token: string): Observable<any>{
    // type -> sender or receiver
    return this.apiService.post('courier_ups/ups_rest_ShipRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUPSTrackingRequestDB(oShipData: shipData, token: string): Observable<any>{
    return this.apiService.post('courier_ups/ups_rest_TrackingRequest.php', token, oShipData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUPSUploadDocumentsRequestDB(aDocuments: Array<shipDocumentData>, token: string): Observable<any>{
    return this.apiService.post('courier_ups/ups_rest_Upload_Document.php', token, aDocuments).pipe(
      map(data => {
        return data;
      })
    );
  }

}
