<div class="container-fluid header-bar d-flex align-items-center bg-light-orange pt-1 pb-1">
  <div class="container-fluid d-flex align-items-center justify-content-between">
    <div class="logo">
      <div routerLink="/home">
        <img src="assets/img/VRSHIPPING_logo.svg" alt="VrShipping Service">
      </div>
    </div>
    <div class="d-flex">
      <div class="box_header_slogan text-end">
        <strong>ASSISTENZA</strong><br />
        <strong>+39 045 8620589</strong><br />
        <strong>assistenza&#64;veronasped.it</strong>
      </div>
    </div>
  </div>


</div>

<div class="d-flex user-bar py-1 container bg-light-orange" *ngIf="vrshippingService.bLoggedIn">
  <div class="flex-fill">
    <a routerLink="/home"><span class="fa fa-home"></span> Home</a>
  </div>
  <div class="flex-fill text-end">
    Benvenuto, <strong>{{vrshippingService.userData.nome}}</strong> |
    <a (click)="logoutUser()"><span class="fas fa-sign-out-alt me-1"></span>Esci</a>
  </div>
</div>
