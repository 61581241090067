<div class="container d-flex flex-column align-items-center justify-content-center pt-5">
  <div class="login-box bg-white p-4 shadow-sm d-flex flex-column align-items-center">
    <div class="logo mb-3">
      <img src="assets/img/VRSHIPPING.svg" alt="Vr Shipping Service">
    </div>
    <form>
      <div class="mb-3">
        <label for="userName">Username</label>
        <input type="text" class="form-control" id="userName" name="userName" aria-describedby="userName"
          placeholder="Username"
          [(ngModel)]="oUserLogin.username"
          (keydown.enter)="loginVRshippingUser()">
      </div>
      <div class="mb-3">
        <label for="password">Password</label>
        <input type="password" name="password" class="form-control" id="password" placeholder="Password"
          [(ngModel)]="oUserLogin.psw"
          (keydown.enter)="loginVRshippingUser()">
      </div>
      <div class="d-grid gap-2">
        <button class="btn" type="button" (click)="loginVRshippingUser()">ACCEDI</button>
      </div>
    </form>
  </div>
  <div class="logo mt-3">
    <img src="assets/img/byVeronasped.svg" alt="logo">
  </div>

  <!--newuserbox-->
  <div class="login-box p-4 mb-2 mt-3">
    <p>
      <strong>Nuovo?</strong><br />
      <strong>Non hai gli accessi?</strong><br />
      Se non sei un cliente <a href="http://www.veronasped.it/" target="_blank"><i>Verona Sped Srl</i></a> e desideri più informazioni sul servizio di spedizioni online
      puoi metterti in contatto con la nostra struttura, saremo lieti di darti tutte le risposte necessarie.
      <br /><br />
      <strong>ASSISTENZA</strong><br>
      <span>Chiama il <a href="tel:00390458620589">+39 045 8620589</a> oppure scrivi a <a href="mailto:info@veronasped.it">info&#64;veronasped.it</a></span>
    </p>
  </div>

</div>
