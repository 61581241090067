<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Servizi attivi corriere {{code_courier}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-wrap align-items-end border bg-light mt-3 mb-3 p-3">
        <div class="flex-fill me-2 mt-2">
          <label>Listino</label>
          <select class="form-control" [(ngModel)]="oService.listino_code">
            <option value="--">-- Seleziona listino --</option>
            <option [value]="list.codice" *ngFor="let list of aList">{{list.description}}</option>
          </select>
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>Codice servizio {{code_courier}}</label>
          <input type="text" class="form-control" placeholder="Es: W" [(ngModel)]="oService.courier_service">
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>Descrizione servizio</label>
          <input type="text" class="form-control" placeholder="Descrizione estesa" [(ngModel)]="oService.courier_service_desc">
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>% Fuel</label>
          <input type="text" class="form-control" placeholder="10" [(ngModel)]="oService.fuel">
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>WorldWide</label>
          <select class="form-control" [(ngModel)]="oService.worldwide">
            <option [value]="'IT'">Nazionale (IT)</option>
            <option [value]="'WW'">Internazionale</option>
          </select>
        </div>
        <div class="flex-fill me-2 mt-2">
          <button class="btn btn-success" (click)="insertCourierService()" [disabled]="oService.courier_service == '' || oService.listino_code == '--'"><i class="fa fa-plus"></i> AGGIUNGI</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="alert alert-warning mt-2 mb-2" *ngIf="bLoadingServices">
        <i class="spinner-border"></i> Caricamento servizi in corso...
      </div>
      <table class="table table-responsive table-hover table-bordered">
        <thead>
          <tr>
            <td class="col-2">Listino</td>
            <td class="col-2">Servizio {{code_courier}}</td>
            <td class="col-3">Descrizione</td>
            <td class="col-2">Fuel %</td>
            <td class="col-2">Worldwide</td>
            <td class="col-1">#</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="aCourierService.length == 0">
            <td colspan="3">Nessun servizio attivo per il corriere</td>
          </tr>
          <tr *ngFor="let service of aCourierService">
            <td>
              {{service.listino_code}}
              <div class="text-muted small">{{getListinoDescription(service.listino_code)}}</div>
            </td>
            <td>
              <input type="text" class="form-control" [(ngModel)]="service.courier_service">
            </td>
            <td>
              <input type="text" class="form-control" [(ngModel)]="service.courier_service_desc">
            </td>
            <td>
              <input type="number" class="form-control" [(ngModel)]="service.fuel">
            </td>
            <td>
              <select class="form-control" [(ngModel)]="service.worldwide">
                <option [value]="'IT'">Nazionale (IT)</option>
                <option [value]="'WW'">Internazionale</option>
              </select>
            </td>
            <td>
              <button class="btn btn-sm btn-success mt-1 me-2" (click)="updateCourierService(service)">
                <i class="fa fa-pencil-alt"></i>
              </button>
              <button class="btn btn-sm btn-danger mt-1" (click)="deleteCourierService(service)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>


