<div class="modal-header">
</div>
<div class="modal-body">

  <div class="d-flex justify-content-center align-items-center flex-column py-3">
    <ng-container *ngIf="type=='danger'">
      <div class="fas fa-times-circle text-danger fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='warning'">
      <div class="fas fa-exclamation-triangle text-warning fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='success'">
      <div class="fas fa-check-circle text-success fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='confirm'">
      <div class="fas fa-question-circle text-info fa-3x my-2"></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='confirmUPS'">
      <div class="fas fa-question-circle text-info fa-3x my-2"></div>
      <div class="text-center">{{message}}</div>
      <div class="mt-3 mb-2">
        <div class="alert alert-warning">
          <h4>RICHIESTO PICKUP - NECESSARIE INFORMAZIONI AGGIUNTIVE</h4>
          Indicare le informazioni aggiuntive necessarie per organizzare il ritiro.
          <div class="d-flex flex-wrap mt-3">
            <div class="mt-2 me-2 flex-fill">
              <label>Orario chiusura - ultimo orario utile</label>
              <select class="form-control" [(ngModel)]="upsPickUpData.closeTime">
                <option value="0900">09:00</option>
                <option value="0930">09:30</option>
                <option value="1000">10:00</option>
                <option value="1030">10:30</option>
                <option value="1100">11:00</option>
                <option value="1130">11:30</option>
                <option value="1200">12:00</option>
                <option value="1230">12:30</option>
                <option value="1300">13:00</option>
                <option value="1330">13:30</option>
                <option value="1400">14:00</option>
                <option value="1430">14:30</option>
                <option value="1500">15:00</option>
                <option value="1530">15:30</option>
                <option value="1600">16:00</option>
                <option value="1630">16:30</option>
                <option value="1700">17:00</option>
                <option value="1730">17:30</option>
                <option value="1800">18:00</option>
              </select>
            </div>
            <div class="mt-2 me-2 flex-fill">
              <label>Ritiro presso casa privata</label>
              <select class="form-control" [(ngModel)]="upsPickUpData.residentialPlace">
                <option value="N">No</option>
                <option value="Y">Sì</option>
              </select>
            </div>
            <div class="mt-2 me-2 flex-fill">
              <label>Tipo ritiro</label>
              <select class="form-control" [(ngModel)]="upsPickUpData.containerCode">
                <option value="01">Pacco/Pacchi</option>
                <option value="02">Lettera/e UPS</option>
              </select>
            </div>
            <div class="mt-2 me-2 flex-fill">
              <label>Note</label>
              <input type="text" class="form-control" placeholder="Note per il corriere" maxlength="57" [(ngModel)]="upsPickUpData.pickupNotes">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--<ng-container *ngIf="type=='confirmBRT'">
      <div class="fas fa-question-circle text-info fa-3x my-2"></div>
      <div class="text-center">{{message}}</div>
      <div class="mt-3 mb-2">
        <div class="alert alert-warning">
          <h4>SE LA SPEDIZIONE PREVEDE DEI PALLET</h4>
          Indicare le informazioni aggiuntive necessarie per organizzare il ritiro.
          <div class="d-flex flex-wrap mt-3">
            <div class="mt-2 me-2">
              <label>Numero di pallet EPAL</label>
              <input type="number" class="form-control" [(ngModel)]="brtOtherData.pallet_EPAL">
            </div>
            <div class="mt-2 me-2">
              <label>Numero di pallet EUR</label>
              <input type="number" class="form-control" [(ngModel)]="brtOtherData.pallet_EUR">
            </div>
            <div class="mt-2 me-2">
              <label>Necessaria sponda idraulica?</label>
              <select class="form-control" [(ngModel)]="brtOtherData.pallet_tail_lift">
                <option value=" ">NO</option>
                <option value="S">SI</option>
              </select>
            </div>
          </div>
        </div>

      </div>
    </ng-container>-->

  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-success me-2" *ngIf="type == 'confirm'" (click)="getConfirmResponse(true)">CONFERMA</button>
    <!--<button type="button" class="btn btn-success me-2" *ngIf="type == 'confirmBRT'" (click)="getConfirmResponseBRT()">CONFERMA</button>-->
    <button type="button" class="btn btn-success me-2" *ngIf="type == 'confirmUPS'" (click)="getConfirmResponseUPS()">CONFERMA</button>
    <button type="button" class="btn btn-secondary" (click)="getConfirmResponse(false)">ANNULLA</button>
  </div>
  <!--<button type="button" class="btn btn-secondary" *ngIf="type!='confirm'" (click)="activeModal.close('Close click')">CHIUDI</button>-->
</div>
