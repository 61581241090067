import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {VrshippingService} from "./services/vrshipping.service";
import {environment} from "../environments/environment";
import {FormsModule} from "@angular/forms";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import { HomepageComponent } from './pages/homepage/homepage.component';
import { Error404Component } from './pages/error404/error404.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SendersComponent } from './pages/senders/senders.component';
import { RecipientsComponent } from './pages/recipients/recipients.component';
import { ShippingArchiveComponent } from './pages/shipping-archive/shipping-archive.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MenuComponent } from './components/menu/menu.component';
import { UsersComponent } from './pages/users/users.component';
import { CourierPageComponent } from './pages/courier-page/courier-page.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddressProfileComponent } from './components/address-profile/address-profile.component';
import { ReceiversComponent } from './pages/receivers/receivers.component';
import { CourierEditComponent } from './components/courier-edit/courier-edit.component';
import { CourierServiceComponent } from './components/courier-service/courier-service.component';
import { ListPageComponent } from './pages/list-page/list-page.component';
import { ListDuplicatorComponent } from './components/list-duplicator/list-duplicator.component';
import { ListShipUpgradeComponent } from './components/list-ship-upgrade/list-ship-upgrade.component';
import { ShippingDataComponent } from './pages/shipping-data/shipping-data.component';
import { ShipSenderComponent } from './components/ship-sender/ship-sender.component';
import { ShipReceiverComponent } from './components/ship-receiver/ship-receiver.component';
import { ShipPackageComponent } from './components/ship-package/ship-package.component';
import { CourierDhlComponent } from './components/courier-dhl/courier-dhl.component';
import { CourierFedexComponent } from './components/courier-fedex/courier-fedex.component';
import { CourierDhlTrackingComponent } from './components/courier-dhl-tracking/courier-dhl-tracking.component';
import { CourierBrtComponent } from './components/courier-brt/courier-brt.component';
import { CourierBrtTrackingComponent } from './components/courier-brt-tracking/courier-brt-tracking.component';
import { CourierFedexTrackingComponent } from './components/courier-fedex-tracking/courier-fedex-tracking.component';
import { CourierUpsComponent } from './components/courier-ups/courier-ups.component';
import { CourierUpsTrackingComponent } from './components/courier-ups-tracking/courier-ups-tracking.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    Error404Component,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SendersComponent,
    RecipientsComponent,
    ShippingArchiveComponent,
    AlertDialogComponent,
    MenuComponent,
    UsersComponent,
    CourierPageComponent,
    UserEditComponent,
    LoaderComponent,
    AddressProfileComponent,
    ReceiversComponent,
    CourierEditComponent,
    CourierServiceComponent,
    ListPageComponent,
    ListDuplicatorComponent,
    ListShipUpgradeComponent,
    ShippingDataComponent,
    ShipSenderComponent,
    ShipReceiverComponent,
    ShipPackageComponent,
    CourierDhlComponent,
    CourierFedexComponent,
    CourierDhlTrackingComponent,
    CourierBrtComponent,
    CourierBrtTrackingComponent,
    CourierFedexTrackingComponent,
    CourierUpsComponent,
    CourierUpsTrackingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxDatatableModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NgbTooltipModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initApp,
    multi: true,
    deps: [HttpClient, VrshippingService]
  }],
  bootstrap: [AppComponent]
})

export class AppModule { }

// HttpClient -> Http
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initApp(http: HttpClient, vrshippingService: VrshippingService) {
  return () => {
    return http.get(environment.apiUrl + 'get_current_settings.php')
      .toPromise()
      .then((resp: any) => {
        vrshippingService.setSettings(resp.settings);
      });
  };
};
