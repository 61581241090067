<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Tracking UPS</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-12">

      <div class="alert alert-danger" *ngIf="bErrorLoadingData">
        <h3>Errore nel caricamento dei dati</h3>
        Il sistema non è riuscito ad identificare la tua spedizione, contatta assistenza per maggiori informazioni.
      </div>

      <section id="dataDetails" *ngIf="!bErrorLoadingData && oTrackingResponse">
        <div *ngIf="oTrackingResponse.response && oTrackingResponse.response.errors">
          <div class="alert alert-danger" *ngFor="let error of oTrackingResponse.response.errors">
            <h5>{{error.code}}</h5>
            {{error.message}}
          </div>
        </div>

        <div *ngIf="oTrackingResponse.trackResponse">
          <div *ngFor="let ship of oTrackingResponse.trackResponse.shipment ">
            <strong>Spedizione: {{ship.inquiryNumber }}</strong>
            <hr />
            <div class="mt-2" *ngFor="let pack of ship.package">
              <div class="w-100 border border-primary b-1 mb-2 p-1" style="--bs-bg-opacity: .1;">

                <div class="text-center mb-2 mt-2">
                  <strong>Tracking: {{pack.trackingNumber}}</strong>
                </div>

                <div class="mt-3 mx-auto">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let step of pack.activity">
                        <td>
                          {{step.date }} - {{step.gmtTime}}
                          <div class="small text-muted">
                            offset {{step.gmtOffset}}
                          </div>
                        </td>
                        <td>
                          <div>{{step.location?.address?.city}} {{step.location?.address?.country}}</div>
                          {{step.status.description}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>

      </section>

    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
