<app-loader *ngIf="bLoadingData"></app-loader>

<div class="container pt-2 pb-4 bg-white">
  <div class="row">
    <div class="col-12">
      <h5>Nuova spedizione <span *ngIf="vrshippingService.bPallet">PALLET</span></h5>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-wrap justify-content-center text-center">
        <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 flex-fill" (click)="openSenderData()">
          <i [class]="checkShipSenderData() ? 'fa-check-circle text-success': 'fa-exclamation-triangle text-danger'" class="fa"></i>
          MITTENTE
          <div class="small text-muted">
            {{oShipData.mit_agency}} - {{oShipData.mit_state}}<br />
            {{oShipData.mit_address}}
          </div>
        </div>
        <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 flex-fill" (click)="openReceiverData()">
          <i [class]="checkShipReceiverData() ? 'fa-check-circle text-success': 'fa-exclamation-triangle text-danger'" class="fa"></i>
          DESTINATARIO
          <div class="small text-muted">
            {{oShipData.dest_agency}} - {{oShipData.dest_state}}<br />
            {{oShipData.dest_address}}
          </div>
        </div>
        <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 flex-fill" (click)="openPackageData()">
          <i [class]="checkShipPackageData() ? 'fa-check-circle text-success': 'fa-exclamation-triangle text-danger'" class="fa"></i>
          COLLI
          <div class="small text-muted">
            Nr. {{oShipData.packages.length}} ({{oShipData.total_weight | number : '1.2-2' }}Kg)
          </div>
          <div class="d-flex mt-2 small text-muted">
            <div class="flex-fill text-success me-2 mt-2" *ngIf="oShipData.insurrence"><i class="fa fa-car-crash"></i> Assicurazione</div>
            <div class="flex-fill text-success me-2 mt-2" *ngIf="oShipData.duty_type == 'DDP'"><i class="fa fa-globe"></i> DDP</div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4 mb-4 text-center" *ngIf="serviceSelected.courier_code == ''">
        <button class="btn btn-success" *ngIf="!bLoadService" [disabled]="!bSenderData || !bReceiverData || !bPackageData" (click)="loadCourierServices()"><i class="fa fa-check-circle"></i> CARICA SERVIZI</button>
        <button class="btn btn-success" *ngIf="bLoadService" (click)="resetShipData()"><i class="fa fa-check-circle"></i> RETTIFICA DATI SPEDIZIONE</button>

        <!--<button class="btn btn-success" *ngIf="bLoadService" (click)="selectService(serviceSelected)"><i class="fa fa-check-circle"></i> NUOVA SPEDIZIONE</button>-->

        <div class="small mt-2" *ngIf="bLoadService"><u>Il prezzo potrebbe variare dopo verifica della merce reale effettuata dallo spedizioniere</u></div>
        <div class="small mt-3" *ngIf="oShipData.duty_type == 'DDP'">
          <label>Accise doganali:</label> fatturate separatamente a consegna avvenuta
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12 mb-4">
      <div class="alert alert-warning" *ngIf="!bLoadService">
        <h5><i class="fa fa-exclamation-triangle"></i> Dati incompleti</h5>
        Occorre inserire un mittente, un destinatario e le informazioni relative ai pacchi per poter visualizzare i servizi disponibili
      </div>

      <section *ngIf="bLoadService">

        <!-- UPS COURIER -->
        <app-courier-ups *ngIf="checkUserCourier('UPS') && (serviceSelected.courier_code == 'UPS' || serviceSelected.courier_code == '') && !vrshippingService.bPallet" [oShipData]="oShipData" (saveAnswer)="selectService($event)"></app-courier-ups>

        <!-- BRT COURIER -->
        <app-courier-brt *ngIf="checkUserCourier('BRT') && (serviceSelected.courier_code == 'BRT' || serviceSelected.courier_code == '') || vrshippingService.bPallet" [oShipData]="oShipData" (saveAnswer)="selectService($event)"></app-courier-brt>

        <!-- FEDEX COURIER -->
        <app-courier-fedex *ngIf="checkUserCourier('FEDEX') && (serviceSelected.courier_code == 'FEDEX' || serviceSelected.courier_code == '') && !vrshippingService.bPallet" [oShipData]="oShipData" (saveAnswer)="selectService($event)"></app-courier-fedex>

        <!-- DHL COURIER -->
        <app-courier-dhl *ngIf="checkUserCourier('DHL') && (serviceSelected.courier_code == 'DHL' || serviceSelected.courier_code == '') && !vrshippingService.bPallet" [oShipData]="oShipData" (saveAnswer)="selectService($event)"></app-courier-dhl>

      </section>

    </div>
  </div>

</div>
