import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  countryData, courierPackResponse,
  courierServiceAvailableData,
  courierServiceData,
  shipData, shipDocumentData,
  shipSpecialServiceData
} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-courier-ups',
  templateUrl: './courier-ups.component.html',
  styleUrls: ['./courier-ups.component.scss']
})
export class CourierUpsComponent {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<courierServiceAvailableData>();

  public bLoadingData: boolean = false;
  public bServiceSelected: boolean = false;
  public bPaperless: boolean = false;
  public isCollapsed: boolean = true;

  public aCourierService: Array<courierServiceData> = [];
  public aEuRegionCountries: Array<countryData> = [];
  public oSpecialService: shipSpecialServiceData = this.vrshippingService.getNewshipSpecialServiceData();
  public aAvailableService: Array<courierServiceAvailableData> = [];
  public oPaperlessDocument: shipDocumentData = this.vrshippingService.getNewShipDocumentData();

  public oUPSShipNotification: any = null;
  public oUPSShipResponse: any = null;
  public oUPSPickupErrors: Array<{code: string, message: string}> = [];
  public oUPSPickupData: any = null;
  public aUPSDocumentIDs: Array<string> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.aEuRegionCountries = this.vrshippingService.settingsData.countries.filter((x: countryData) => x.eu_region);
    this.getCourierServiceForRate();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  convertToDateString(yyyymmdd: string): string {
    // Estrai anno, mese e giorno dalla stringa
    const year = yyyymmdd.substring(0, 4);
    const month = yyyymmdd.substring(4, 6);
    const day = yyyymmdd.substring(6, 8);

    // Formatta la data nel formato yyyy-mm-dd
    return `${year}-${month}-${day}`;
  }

  convertToTimeString(hhmmss: string): string {
    // Estrai ora, minuti e secondi dalla stringa
    const hour = hhmmss.substring(0, 2);
    const minute = hhmmss.substring(2, 4);
    const second = hhmmss.substring(4, 6);

    // Formatta l'ora nel formato hh:mm:ss
    return `${hour}:${minute}:${second}`;
  }

  getCourierServiceForRate() {

    this.bLoadingData = true;
    let worldwide: string = this.oShipData.dest_state == 'IT' ? 'IT' : 'WW';

    this.vrshippingService.getCourierServiceRateListDB('UPS', this.oShipData.total_weight, worldwide, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.services && get_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.services.error);
        } else {
          this.aCourierService = get_response.services;
          this.upsRateRequest();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  upsRateRequest() {

    this.bLoadingData = true;

    this.vrshippingService.postUPSRateRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.ups && get_response.ups.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi disponibili UPS: ' + JSON.stringify(get_response.ups.error));
        } else {

          let response: any = null;
          if(get_response && get_response.ups.data) response = get_response.ups.data;

          // Error response
          if(response && response.response && response.response.errors){
            let errors: string = '';

            response.response.errors.forEach((error: any) => {
              errors = errors + error.message + ' ';
            });
            this.openDialog('danger', 'Attenzione, impossibile caricare i servizi UPS ' + errors);
          }

          // Rate response
          if(response && response.RateResponse){
            if(response.RateResponse.Response.ResponseStatus.Code == '1'){
              response.RateResponse.RatedShipment.forEach((rate: any) => {
                let vrsService: Array<any> = [];
                let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('UPS');
                vrsService = this.aCourierService.filter(x => x.courier_service.toString() === rate.Service.Code);
                if(vrsService.length > 0) {
                  // Add service to available list
                  oAvailableService.list_code = vrsService[0].listino_code;
                  oAvailableService.list_description = vrsService[0].listino_description;
                  oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].price_zone;
                  oAvailableService.courier_service =  rate.Service.Code;
                  oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;
                  if(rate.TotalCharges && rate.TotalCharges.MonetaryValue) oAvailableService.courier_price = Number(rate.TotalCharges && rate.TotalCharges.MonetaryValue);
                  if(!rate.TotalCharges || !rate.TotalCharges) oAvailableService.courier_price = 0;
                  oAvailableService.pickup_date = this.convertToDateString(rate.TimeInTransit.ServiceSummary.EstimatedArrival.Pickup.Date) + ' ' + this.convertToTimeString(rate.TimeInTransit.ServiceSummary.EstimatedArrival.Pickup.Time);
                  oAvailableService.pickup_delivery = this.convertToDateString(rate.TimeInTransit.ServiceSummary.EstimatedArrival.Arrival.Date) + ' ' + this.convertToTimeString(rate.TimeInTransit.ServiceSummary.EstimatedArrival.Arrival.Time);

                  if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
                    let aZonePrice: Array<any> = [];
                    if(this.oShipData.dest_state !== 'IT') aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == oAvailableService.country_zone);
                    if(this.oShipData.dest_state == 'IT'){
                      let province: any = [];
                      province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
                      if(province.length > 0) {
                        aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].price_zone);
                        oAvailableService.country_zone = province[0].price_zone;
                      }
                    }

                    if(aZonePrice.length > 0) {
                      oAvailableService.list_price = aZonePrice[0].price;
                    }

                  }

                  // Fuel supplement
                  oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

                  // Insurance cost
                  if(this.oShipData.insurrence){
                    oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, this.oShipData.dest_state == 'IT' ? vrsService[0].insurrence_it : vrsService[0].insurrence_world);
                    this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
                    this.oSpecialService.type = "II";
                    this.oSpecialService.value = this.oShipData.insurrence_declared;
                    this.oSpecialService.cost = oAvailableService.insurrence_cost;
                    this.oSpecialService.currency = "EUR";
                    oAvailableService.special_services.push(this.oSpecialService);
                  }

                  // DDP
                  if(this.oShipData.duty_type == 'DDP'){
                    this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
                    this.oSpecialService.type = "DD";
                    this.oSpecialService.value = this.oShipData.duty_declared;
                    this.oSpecialService.cost = vrsService[0].ddp;
                    this.oSpecialService.currency = "EUR";
                    oAvailableService.special_services.push(this.oSpecialService);
                    oAvailableService.ddp_cost = vrsService[0].ddp;
                  }

                  // Remote Area
                  let aRemoteArea: Array<any> = [];
                  if(rate.ItemizedCharges && rate.ItemizedCharges.length > 0){
                    rate.ItemizedCharges.forEach((charge: any) => {
                      if(charge.Code == "190") oAvailableService.remote_zone_cost = this.oShipData.dest_state == 'IT' ? vrsService[0].remote_area_it : vrsService[0].remote_area_world;
                    });
                  }

                  // Paperless
                  if(get_response.ups.paperless) oAvailableService.paperless = true;

                  this.aAvailableService.push(oAvailableService);

                }
              });

              // Order aAvailableService by price
              this.aAvailableService.sort((a, b) => {
                return a.list_price - b.list_price;
              });

            }

          }
        }

        setTimeout(()=>{
          this.bLoadingData = false;
        },1000);


      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  selectService(service: courierServiceAvailableData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler spedire con questo servizio: " + service.courier_code +  " - " + service.list_description + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.saveAnswer.emit(service);
        this.bServiceSelected = true;
        this.bPaperless = service.paperless;
        this.completeShipData(service);
      }
    });

  }

  selectServicePickup(service: courierServiceAvailableData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'xl' });
    modalRef.componentInstance.type = 'confirmUPS';
    modalRef.componentInstance.message = "Confermi di voler spedire con questo servizio: " + service.courier_code +  " - " + service.list_description + "?";
    modalRef.componentInstance.questionAnswerUPS.subscribe((pickUpData: any) => {
      if(pickUpData) {
        // Set data for pickup
        this.oShipData.pickupCloseTime = pickUpData.closeTime;
        this.oShipData.pickupLocation = pickUpData.residentialPlace;
        this.oShipData.pickupContainerCode = pickUpData.containerCode;
        this.oShipData.pickupNotes = pickUpData.pickupNotes;

        this.saveAnswer.emit(service);
        this.bServiceSelected = true;
        this.bPaperless = service.paperless;
        this.completeShipData(service);
      }
    });

  }

  completeShipData(service: courierServiceAvailableData){

    this.oShipData.courier_code = service.courier_code;
    this.oShipData.courier_price = service.courier_price;
    this.oShipData.courier_service = service.courier_service;
    this.oShipData.delivery = service.pickup_delivery;
    this.oShipData.duty_cost = service.ddp_cost;
    this.oShipData.fuel_price = service.fuel_cost;
    this.oShipData.insurrence_cost = service.insurrence_cost;
    this.oShipData.pickupdate = service.pickup_date;
    this.oShipData.price = service.list_price;
    this.oShipData.remote_zone = service.remote_zone_cost;
    this.oShipData.special_services = service.special_services;

    if(!this.bPaperless) this.insertShipRequest();

  }

  insertShipRequest() {
    this.bLoadingData = true;
    this.bPaperless = false;
    this.sendShipRequest();
  }

  sendShipRequest() {
    this.bLoadingData = true;

    this.vrshippingService.postUPSShipRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        let response: any = null;
        response = get_response.ups.data;

        this.oUPSShipNotification = [];
        this.oUPSShipResponse = [];

        if (response && response.response && response.response.errors){
          // Errors - no shipment created
          response.response.errors.forEach((error: any) => {
            let errorItem: any = {
              code: error.code,
              message: error.message
            }
            this.oUPSShipNotification.push(errorItem);
          });

        }

        if(response && response.ShipmentResponse){
          // Response OK
          response.ShipmentResponse.ShipmentResults.PackageResults.map((item: any) => {
            let details: any = {
              ship_id: response.ShipmentResponse.ShipmentResults.ShipmentIdentificationNumber,
              tracking: item.TrackingNumber,
              stream: item.ShippingLabel.GraphicImage
            }
            this.oUPSShipResponse.push(details);
            this.downloadPdf(details.stream, 'ups_' + details.tracking)
          });

        }

        if(get_response.ups && get_response.ups.pickup && get_response.ups.pickup.error) {
          // Errors - no pickup created
          this.oUPSPickupErrors = get_response.ups.pickup.error;
        }

        if(get_response && get_response.ups.pickup && get_response.ups.pickup.data && get_response.ups.pickup.data.PickupCreationResponse){
          // Pickup OK
          this.oUPSPickupData = get_response.ups.pickup.data.PickupCreationResponse;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  newShipping() {
    this.bServiceSelected = false;
    this.bPaperless = false;
    this.oUPSShipResponse = null;
    this.oUPSPickupErrors = [];
    this.oUPSPickupData = null;
    this.oUPSShipNotification = null;
    this.aAvailableService = [];

    let newService: courierServiceAvailableData = this.vrshippingService.getNewCourierServiceAvailableData();
    this.saveAnswer.emit(newService);
  }

  uploadFilesToUps(){
    this.bLoadingData = true;

    if(this.oShipData.documents.length > 0) {
      // Call http request from postUPSUploadDocumentsRequestDB service
      this.vrshippingService.postUPSUploadDocumentsRequestDB(this.oShipData.documents, this.vrshippingService.userData.token).subscribe(get_response => {
          // Handle response
          if(get_response.ups && get_response.ups.error) {
            //Error
            this.openDialog('danger', 'Attenzione, documenti non caricati correttamente' + ': ' + get_response.ups.error);
            this.bLoadingData = false;
          } else {

            this.oShipData.documents_id = get_response.ups.ids;

            // Document uploaded - get IDs
            let index: number = 0;
            this.oShipData.documents.forEach((doc: shipDocumentData) => {
              doc.doc_id = get_response.ups.ids[index] ? get_response.ups.ids[index] : '';
              index++;
            });

            this.insertShipRequest();

          }

        },
        error => {
          // API call error
          console.log(error);
          this.bLoadingData = false;
          this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        });
    } else {
      this.insertShipRequest();
    }

  }

  // Converti la stringa base64 in dati binari
  base64ToBlob(base64: any, mimeType: any) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  downloadPdf(base64String: string, fileName: string) {
    /*const source = 'data:application/pdf;base64,${base64String}';
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.gif`
    link.click();*/
    const blob = this.base64ToBlob(base64String, 'image/gif');
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${fileName}.gif`;
    link.click();

// Rilascia l'URL creato per il blob
    URL.revokeObjectURL(url);
  }

  onClickDownloadPdf(){
    this.downloadPdf(this.oShipData.pdf_base64,"ups_shipment_label_"+ this.oShipData.ship_id + ".pdf");
  }

  /************************************/
  /***** PAPERLESS DOCUMENTS **********/
  /************************************/

  addPaperlessFile() {
    if(this.oPaperlessDocument.type !== '--' && this.oPaperlessDocument.b64_image !== '') this.oShipData.documents.push(this.oPaperlessDocument);
    this.oPaperlessDocument = this.vrshippingService.getNewShipDocumentData();
  }

  removePaperlessFile(doc: shipDocumentData) {
    this.oShipData.documents = this.oShipData.documents.filter(x => x.file_name !== doc.file_name);
  }

  public picked(event: any) {

    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file_splitted: string[] = fileList[0].name.split(".");
      this.oPaperlessDocument.format = fileList[0].name.split(".")[file_splitted.length - 1];
      this.oPaperlessDocument.file_name = fileList[0].name;
      if(fileList[0].size > 10000000) {
        this.openDialog('danger', 'Attenzione, il file supera la dimensione massima di 10MB');
        return;
      }
      this.handleInputChange(fileList[0]);
    }

  }

  handleInputChange(files: any) {
    const file = files;
    const reader = new FileReader();
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    const base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    this.oPaperlessDocument.b64_image = base64result;
  }

}
