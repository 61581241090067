import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  countryData,
  courierPackResponse,
  courierServiceAvailableData,
  courierServiceData,
  shipData,
  shipSpecialServiceData
} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-courier-fedex',
  templateUrl: './courier-fedex.component.html',
  styleUrls: ['./courier-fedex.component.scss']
})
export class CourierFedexComponent implements OnInit {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<courierServiceAvailableData>();

  public bLoadingData: boolean = false;
  public aCourierService: Array<courierServiceData> = [];
  public aAvailableService: Array<courierServiceAvailableData> = [];
  public oSpecialService: shipSpecialServiceData = this.vrshippingService.getNewshipSpecialServiceData();
  public aEuRegionCountries: Array<countryData> = [];
  public aRoadRegion: Array<string> = ['AT', 'FR', 'DE', 'SI', 'ES', 'PT', 'BE', 'NL', 'LU', 'PL', 'BG', 'HU', 'CZ', 'SK', 'GB',
    'LV', 'EE', 'LT', 'SE', 'FI', 'DK', 'IE', 'CH', 'LI'];

  public oFedexRateResponse: any = {Notifications: null, RateReplyDetails: []};
  public oFedexRateNotification: any = null;
  public oFedexShipNotification: any = null;
  public oFedexShipResponse: any = null;
  public bServiceSelected: boolean = false;

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.aEuRegionCountries = this.vrshippingService.settingsData.countries.filter((x: countryData) => x.eu_region);
    this.getCourierServiceForRate();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getCourierServiceForRate() {

    this.bLoadingData = true;
    let worldwide: string = this.oShipData.dest_state == 'IT' ? 'IT' : 'WW';

    this.vrshippingService.getCourierServiceRateListDB('FEDEX', this.oShipData.total_weight, worldwide, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.services && get_response.services.error) {
          //Error
          //this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.services.error);
        } else {
          this.aCourierService = get_response.services;
          this.aCourierService.map(x => {
            x.courier_service = x.courier_service.replace("F-", "");
            return x;
          });

          this.fedexRateRequest();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  fedexRateRequest() {

    this.bLoadingData = true;

    this.vrshippingService.postFedexRateRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.fedex && get_response.fedex.error) {
          //Error
          //this.openDialog('danger', 'Attenzione, impossibile caricare i servizi disponibili: ' + JSON.stringify(get_response.fedex.error));
        } else {

          let response: any = null;
          if(get_response.fedex.data) response = get_response.fedex.data;

          if(response){
            if(response.errors) this.openDialog('danger', 'Attenzione, impossibile caricare i servizi disponibili per FEDX');
            if(!response.errors){
              this.oFedexRateResponse = response.output.rateReplyDetails;

              this.oFedexRateResponse.map((service: any) => {

                let codListinoVrSped = "";
                let bRoadZone = this.aRoadRegion.indexOf(this.oShipData.dest_state) !== -1 ? true : false;

                if(service.serviceDescription.code == '04') {
                  if (bRoadZone) codListinoVrSped = "ECO";
                  //if (!bRoadZone) codListinoVrSped = "WWMER";
                }

                if(service.serviceDescription.code == '2P') {
                  codListinoVrSped = "WWMER";
                }

                if(codListinoVrSped){
                  let vrsService: Array<any> = [];
                  let oAvailableService: courierServiceAvailableData = this.vrshippingService.getNewAvailableCourierService('FEDEX');
                  vrsService = this.aCourierService.filter(x => x.courier_service.toString() === service.serviceDescription.code && x.listino_code == codListinoVrSped);

                  if(vrsService.length > 0) {
                    // Add service to available list
                    oAvailableService.courier_service_other = service.serviceType;
                    oAvailableService.list_code = vrsService[0].listino_code;
                    oAvailableService.list_description = vrsService[0].listino_description;
                    oAvailableService.country_zone = this.vrshippingService.settingsData.countries.filter((x: any)=> x.iso == this.oShipData.dest_state)[0].price_zone;
                    oAvailableService.courier_service = service.serviceDescription.code;
                    oAvailableService.courier_service_desc = vrsService[0].courier_service_desc;

                    let totalFedex: string = "";
                    if(Array.isArray(service.ratedShipmentDetails)) totalFedex = service.ratedShipmentDetails[0].totalNetChargeWithDutiesAndTaxes;
                    if(!Array.isArray(service.ratedShipmentDetails)) totalFedex = service.RatedShipmentDetails.totalNetChargeWithDutiesAndTaxes;

                    oAvailableService.courier_price = Number(totalFedex);
                    oAvailableService.pickup_date = "";
                    oAvailableService.pickup_delivery = service.operationalDetail.deliveryDate ? service.operationalDetail.deliveryDate : null;

                    if(vrsService[0].listino_ship && vrsService[0].listino_ship.length > 0) {
                      let aZonePrice: Array<any> = [];
                      if(this.oShipData.dest_state !== 'IT') aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == oAvailableService.country_zone);
                      if(this.oShipData.dest_state == 'IT'){
                        let province: any = [];
                        province = this.vrshippingService.settingsData.provinces.filter((x: any) => x.sigla == this.oShipData.dest_us_prov);
                        if(province.length > 0) {
                          aZonePrice = vrsService[0].listino_ship.filter((x:any)=>x.zone == province[0].price_zone);
                          oAvailableService.country_zone = province[0].price_zone;
                        }
                      }

                      if(aZonePrice.length > 0) {
                        oAvailableService.list_price = aZonePrice[0].price;
                      }

                    }

                    // Fuel supplement
                    oAvailableService.fuel_cost = (oAvailableService.list_price * vrsService[0].fuel) / 100;

                    // Insurrence cost
                    if(this.oShipData.insurrence){
                      oAvailableService.insurrence_cost = this.vrshippingService.getInsurranceCost(oAvailableService.list_price, vrsService[0].insurrence_perc, this.oShipData.dest_state == 'IT' ? vrsService[0].insurrence_it : vrsService[0].insurrence_world);
                      this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
                      this.oSpecialService.type = "II";
                      this.oSpecialService.value = this.oShipData.insurrence_declared;
                      this.oSpecialService.cost = oAvailableService.insurrence_cost;
                      this.oSpecialService.currency = "EUR";
                      oAvailableService.special_services.push(this.oSpecialService);
                    }

                    // DDP
                    if(this.oShipData.duty_type == 'DDP'){
                      this.oSpecialService = this.vrshippingService.getNewshipSpecialServiceData();
                      this.oSpecialService.type = "DD";
                      this.oSpecialService.value = 0;
                      this.oSpecialService.cost = vrsService[0].ddp;
                      this.oSpecialService.currency = "EUR";
                      oAvailableService.special_services.push(this.oSpecialService);
                      oAvailableService.ddp_cost = vrsService[0].ddp;
                    }

                    // Remote Area
                    let aRemoteArea: Array<any> = [];
                    let objectDetails: any = null;
                    if(Array.isArray(service.ratedShipmentDetails)) objectDetails = service.ratedShipmentDetails[0];
                    if(!Array.isArray(service.ratedShipmentDetails)) objectDetails = service.ratedShipmentDetails;

                    if(Array.isArray(objectDetails.shipmentRateDetail.surCharges)) {
                      aRemoteArea = objectDetails.shipmentRateDetail.surCharges.filter((x:any) => x.type == 'OUT_OF_DELIVERY_AREA');
                    } else {
                      if(objectDetails.shipmentRateDetail.surCharges.type == 'OUT_OF_DELIVERY_AREA'){
                        aRemoteArea.push(objectDetails.shipmentRateDetail.surCharges);
                      }
                    }

                    if(aRemoteArea.length > 0) {
                      aRemoteArea.map((x: any) => {
                        if(x.amount && x.amount !== '') oAvailableService.remote_zone_cost = this.oShipData.dest_state == 'IT' ? vrsService[0].remote_area_it : vrsService[0].remote_area_world;
                      });
                    }

                    // Paperless
                    //if(service.Charges.Charge.filter((x:any) => x.ChargeCode == 'WY').length > 0) oAvailableService.paperless = true;

                    this.aAvailableService.push(oAvailableService);

                  }
                }

              });

            }
          }


          // OLD **************************
          if(get_response.fedex.data){

            /*let response: any = null;
            response = get_response.fedex.data.RateReplyDetails ? get_response.fedex.data.RateReplyDetails : null;
            if(get_response.fedex.data.HighestSeverity == 'FAILURE') {
              this.oFedexRateNotification = get_response.fedex.data.Notifications ? get_response.fedex.data.Notifications : null;
            }

            if(this.oFedexRateResponse) {

              if(Array.isArray(response)) this.oFedexRateResponse = response;
              if(!Array.isArray(response)) {
                this.oFedexRateResponse = [];
                this.oFedexRateResponse.push(response);
              }



              this.aAvailableService.sort((a, b) => a.list_price - b.list_price);
            }*/

          }
        }

        setTimeout(()=>{
          this.bLoadingData = false;
        },1000);


      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  selectService(service: courierServiceAvailableData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler spedire con questo servizio: " + service.courier_code +  " - " + service.list_description + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.saveAnswer.emit(service);
        this.bServiceSelected = true;
        //this.bPaperless = service.paperless;
        this.completeShipData(service);
      }
    });

  }

  completeShipData(service: courierServiceAvailableData){

    this.oShipData.courier_code = service.courier_code;
    this.oShipData.courier_price = service.courier_price;
    this.oShipData.courier_service = service.courier_service;
    this.oShipData.delivery = "";
    this.oShipData.duty_cost = service.ddp_cost;
    this.oShipData.fuel_price = service.fuel_cost;
    this.oShipData.insurrence_cost = service.insurrence_cost;
    this.oShipData.pickupdate = this.oShipData.date + "T" + this.oShipData.hour;
    this.oShipData.price = service.list_price;
    this.oShipData.remote_zone = service.remote_zone_cost;
    this.oShipData.courier_service_other = service.courier_service_other;
    this.oShipData.dest_europe = false;

    // Check EU Region
    let aCountry: Array<countryData> = [];
    aCountry = this.aEuRegionCountries.filter(x => x.iso == this.oShipData.dest_state);
    if(aCountry && aCountry.length > 0) this.oShipData.dest_europe = true;

    this.insertShipRequest();

    //if(!this.bPaperless) this.insertShipRequest();

  }

  insertShipRequest() {
    this.bLoadingData = true;
    this.sendShipRequest();

  }

  sendShipRequest() {

    this.bLoadingData = true;

    this.vrshippingService.postFedexShipRequestDB(this.oShipData, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.fedex && get_response.fedex.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile contattare i server FEDEX ' + JSON.stringify(get_response.fedex.error));
        } else {
          let fedexResponse: any = get_response.fedex.data;
          this.oFedexShipNotification = [];
          this.oFedexShipResponse = [];

          if(fedexResponse.errors && fedexResponse.errors.length > 0){
            fedexResponse.errors.forEach((error: any) => {
              let errorItem: any = {
                code: error.code,
                message: error.message
              }
              this.oFedexShipNotification.push(errorItem);
            });
          }

          if(!fedexResponse.errors){
            fedexResponse.output.transactionShipments.map((item: any) => {
              item.pieceResponses.map((piece:any) => {
                let details: any = {
                  ship_id: piece.masterTrackingNumber,
                  tracking: piece.trackingNumber,
                  stream: piece.packageDocuments.filter((x:any) => x.contentType == 'LABEL')[0].encodedLabel
                }
                this.oFedexShipResponse.push(details);
                this.downloadPdf(details.stream, 'fedex_' + details.tracking)
              });
            });
          }

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  newShipping() {
    this.bServiceSelected = false;
    this.oFedexShipResponse = null;
    this.oFedexShipNotification = null;
    this.oFedexRateResponse = null;
    this.oFedexRateNotification = null;
    this.aAvailableService = [];

    let newService: courierServiceAvailableData = this.vrshippingService.getNewCourierServiceAvailableData();
    this.saveAnswer.emit(newService);
  }

  downloadPdf(base64String: string, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

}
